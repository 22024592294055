import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

// Components
import { Footer, PostCard, SiteNav, Wrapper } from 'components';
// @ts-ignore
import { Phone, TabletUp } from 'components/MediaQuery';
import { BurgerMenu } from 'components/SiteNav/BurgerMenu';

// Layouts
import { SEOContent } from 'layouts';

// Styles
import {
  inner,
  outer,
  PostFeed,
  PostFeedRaise,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from 'styles';

// Misc.
import config from '../website-config';

// Types
import { Posts } from 'pages';

interface Props {
  data: {
    allAirtable: {
      nodes: Posts[];
      totalCount: number;
    };
  };
  pageContext: {
    tag: string;
  };
  uri: string;
}

const TagsPage = ({ data, pageContext, uri }: Props) => {
  const { nodes, totalCount } = data.allAirtable;
  const tag = pageContext.tag;

  return (
    <SEOContent title={tag}>
      <Helmet>
        <html lang={config.lang} />
        <title>{tag}</title>
        <meta name="description" />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${tag} - ${config.title}`} />
        <meta property="og:url" content={config.siteUrl + uri} />
        {config.facebook && (
          <meta property="article:publisher" content={config.facebook} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${tag} - ${config.title}`} />
        <meta name="twitter:url" content={config.siteUrl + uri} />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
      </Helmet>
      <Wrapper>
        <header className="no-cover" css={[outer, SiteHeader]}>
          <div css={inner}>
            <Phone>
              <BurgerMenu />
            </Phone>
            <TabletUp>
              <SiteNav isHome={false} />
            </TabletUp>
            <SiteHeaderContent>
              <SiteTitle>{tag}</SiteTitle>
              <SiteDescription>
                <>
                  A collection of {totalCount > 1 && `${totalCount} posts`}
                  {totalCount === 1 && '1 post'}
                  {totalCount === 0 && 'No posts'}
                </>
              </SiteDescription>
            </SiteHeaderContent>
          </div>
        </header>
        <main css={[SiteMain, outer]}>
          <div css={inner}>
            <div css={[PostFeed, PostFeedRaise]}>
              {nodes.map(({ data, fields }: Posts) => (
                <PostCard
                  key={data.post_id}
                  post={data}
                  postId={fields.postId}
                />
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </SEOContent>
  );
};

export default TagsPage;

export const query = graphql`
  query($tag: String) {
    allAirtable(
      filter: { table: { eq: "Posts" }, fields: { tags: { eq: $tag } } }
      limit: 2000
      sort: { fields: data___created_date, order: DESC }
    ) {
      nodes {
        fields {
          postId
        }
        data {
          title
          excerpt
          time_to_read
          created_date(formatString: "MM-DD-YYYY")
          attachments {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          tags
          post_id
          author {
            data {
              first_name
              full_name
              member_slug
            }
          }
        }
      }
      totalCount
    }
  }
`;
